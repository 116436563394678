<mat-card class="thank-you-card">
  <div class="full-width close-icon" fxLayoutAlign="end" fxLayout="row">
    <button mat-icon-button (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="thank-you-heading">THANK YOU</div>
  <div class="text">
    We have received your information
    and will contact you soon!
  </div>
</mat-card>
