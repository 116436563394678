import { Component, OnInit } from '@angular/core';
import news from '../../data/news.json';

@Component({
  selector: 'queblo-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {
  news = news;

  constructor() { }

  ngOnInit(): void {
  }

}
