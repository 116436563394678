import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import projects from '../data/projects.json';

@Component({
  selector: 'queblo-projects-list',
  templateUrl: './projects-list.component.html',
  styleUrls: ['./projects-list.component.scss']
})
export class ProjectsListComponent implements OnInit {
  projects = [];

  constructor(private route: ActivatedRoute, private router: Router) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnInit(): void {
    this.getProjects();
  }

  getProjects(): void {
    const type = this.route.snapshot.paramMap.get('type');
    this.projects = projects[type];
  }

}
