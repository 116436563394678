<h1>{{project.name}}</h1>
<div class="description-text">{{project.description}}</div>
<div class="gallery-container">
  <mat-tab-group
    class="image-gallery"
    headerPosition="below"
    mat-align-tabs="center"
    [selectedIndex]="defaultSelectedIndex"
    (selectedIndexChange)="selectedIndexChange($event)"
  >
    <mat-tab *ngFor="let image of project.images">
      <ng-template mat-tab-label>
        <img src="../../assets/images/project{{image.thumbnailURL}}" class="label-image"/>
      </ng-template>
      <div>
        <img src="../../assets/images/project{{image.imageURL}}" class="full-image"/>
      </div>
    </mat-tab>
  </mat-tab-group>
  <button mat-icon-button (click)="goToPreviousImage()" class="gallery-left-arrow" fxHide.lt-md="">
    <mat-icon>keyboard_arrow_left</mat-icon>
  </button>
  <button mat-icon-button (click)="goToNextImage()" class="gallery-right-arrow" fxHide.lt-md="">
    <mat-icon>keyboard_arrow_right</mat-icon>
  </button>
  <div class="dots-container" fxLayout="row" fxLayoutAlign="center">
    <div
      class="dot"
      *ngFor="let image of project.images; let i=index"
      [ngClass]="{'active': defaultSelectedIndex == i}"
      (click)="defaultSelectedIndex = i;"
    ></div>
  </div>
</div>
<ng-container *ngIf="project.name === 'Restoration'">
  <h1 class="restoration">Our Approach</h1>
  <div fxLayout.gt-md="row" class="approach-steps-container">
    <div class="approach-step">
      <h2>Evaluating</h2>
      <div class="description-text">Our team will help you review your exteriors and assess any damage. We’ll take a “fine-tooth-comb” to your property and document every instance of storm damage.</div>
      <div class="step-number">1</div>
      <img src="assets/images/stepArrow.png" class="step-arrow"/>
    </div>
    <div class="approach-step">
      <h2>Record</h2>
      <div class="description-text">Our team is not only trained to identify weather related damages but also able to provide you a visual assessment with photos/videos of the concerned area. So we know exactly how we can restore your property.</div>
      <div class="step-number">2</div>
      <img src="assets/images/stepArrow.png" class="step-arrow"/>
    </div>
    <div class="approach-step">
      <h2>Reach Out</h2>
      <div class="description-text">Reach out to your insurance company. The majority of insurance companies have an app from which a claim can be filed, as well as 24/7 call centers to help you start the process easily.</div>
      <div class="step-number last">3</div>
    </div>
  </div>
  <h1 class="restoration">Fixing The Damage</h1>
  <div class="description-text restoration-text-heading">Evaluation</div>
  <div class="description-text restoration-text">We’ll work alongside your insurance company to determine all the
    components that sustained storm damage and ensure that you are receiving adequate compensation to repair all parts
    of your home in a manner that will continue to protect your home for many years to come.
  </div>
  <div class="description-text restoration-text-heading">Restoration</div>
  <div class="description-text restoration-text">After you review all the restoration specifications to make sure they
    cover everything you need, including the style you want, Queblo will provide you with the dates for the build and
    make sure all the details are transparent and work for you.
  </div>
  <div class="description-text restoration-text-heading">Quality Assurance</div>
  <div class="description-text restoration-text">After the restoration, we’ll do a walk through to ensure that
    everything is done to your satisfaction and meets our rigorous standards.
    If there’s any additional work that needs to be completed, we’ll schedule that with you.
  </div>
  <div *ngIf="project.name === 'Restoration'" class="contact-info" fxLayout="row" fxLayoutAlign="space-between start"
       fxLayout.lt-md="column" fxLayoutAlign.lt-md="start stretch">
    <div fxFlex="40" class="want-to-know-more">
      <h1>Want to Know More?</h1>
      <div class="description-text">
        The first step is easy, just contact us to
        speak with one of our representatives today.
      </div>
      <div class="description-text phone-text">
        <b>Phone: </b>(612) 208-2729
      </div>
      <div class="description-text">
        <b>Email: </b>info@queblo.com
      </div>
      <div class="certificate"><img src="/assets/images/certificate.jpg"></div>
    </div>
    <mat-card fxFlex="50" class="contact-us-card">
      <h1>Contact Us</h1>
      <div *ngIf="!isContactSubmitted" class="inputs-container">
        <mat-form-field class="full-width">
          <mat-label>Your Name</mat-label>
          <input type="text" matInput [(ngModel)]="name"/>
        </mat-form-field>
        <mat-form-field class="full-width">
          <mat-label>Email</mat-label>
          <input type="email" matInput [(ngModel)]="email"/>
        </mat-form-field>
        <mat-form-field class="full-width">
          <mat-label>Message</mat-label>
          <textarea matInput #messageInput maxlength="150" [(ngModel)]="message"></textarea>
          <mat-hint align="end">{{messageInput.value.length}} / 150</mat-hint>
        </mat-form-field>
      </div>
      <button *ngIf="!isContactSubmitted" mat-fab color="accent" class="submit-button" (click)="submitContactForm()">
        <mat-icon>send</mat-icon>
      </button>
      <queblo-thank-you-card *ngIf="isContactSubmitted" (onClose)="isContactSubmitted = false;"></queblo-thank-you-card>
    </mat-card>
  </div>
</ng-container>

