import {Component, OnDestroy, OnInit} from '@angular/core';
import {interval, Subscription} from 'rxjs';
import {InstService} from '../inst.service';
import instagramFeed from '../data/instagram.json';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit, OnDestroy {
  private intervalSubscription: Subscription;
  selectedSlideIndex = 0;
  instFeed = instagramFeed;

  constructor(private instService: InstService) { }

  ngOnInit(): void {
    this.intervalSubscription = interval(5000).subscribe(() => this.switchSlide());
    // instagramFeed.forEach((post, index) => {
    //   this.instService.getInstFeed({postUrl: post.link}).subscribe(result => {
    //     this.instFeed[index] = {link: post.link, image: result.thumbnail_url};
    //   });
    // });
  }

  switchSlide(): void {
    this.selectedSlideIndex++;
    if (this.selectedSlideIndex > 2) {
      this.selectedSlideIndex = 0;
    }
  }

  ngOnDestroy(): void {
    this.intervalSubscription.unsubscribe();
  }
}
