import {AfterViewInit, ChangeDetectorRef, Component, Input, OnInit, ViewChild} from '@angular/core';
import {Project} from './project';
import {MatTabGroup} from '@angular/material/tabs';
import {timer} from 'rxjs';
import {take} from 'rxjs/operators';
import {ContactService} from '../service/contact.service';

@Component({
  selector: 'queblo-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss'],
})
export class ProjectComponent implements AfterViewInit {
  @ViewChild(MatTabGroup, { static: false }) tabGroup!: MatTabGroup;
  @Input() project: Project;
  defaultSelectedIndex = 0;

  isContactSubmitted = false;
  name = '';
  email = '';
  message = '';
  constructor(private contactService: ContactService) { }

  ngAfterViewInit(): void {
    timer(200)
      .pipe(take(1))
      .subscribe(() => this.tabGroup.realignInkBar());
  }

  goToPreviousImage(): void {
    this.defaultSelectedIndex--;
    if (this.defaultSelectedIndex < 0) {
      this.defaultSelectedIndex = 0;
    }
  }

  goToNextImage(): void {
    this.defaultSelectedIndex++;
    if (this.defaultSelectedIndex >= this.project.images.length) {
      this.defaultSelectedIndex = this.project.images.length - 1;
    }
  }

  selectedIndexChange(newIndex: number): void {
    this.defaultSelectedIndex = newIndex;
  }

  submitContactForm(): void {
    const name = this.name;
    const email = this.email;
    const message = this.message;
    this.contactService.sendContactData({
      name,
      email,
      message
    }).subscribe(result => {
      console.log(result);
      this.name = '';
      this.email = '';
      this.message = '';
      this.isContactSubmitted = true;
    });
  }
}
