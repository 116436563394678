<div class="container video-container">
  <cloudflare-stream
    [controls]="true"
    [autoplay]="true"
    [loop]="true"
    [muted]="true"
    src="f4b120ac1efc956f68b8493be693ab71"
  ></cloudflare-stream>
</div>
<div class="text-block" fxLayout.gt-sm="row" fxLayout.lt-md="column" fxLayoutAlign.gt-sm="start start">
  <h1 fxFlex="25">About</h1>
  <div fxFlex>
    <div class="description-text">
      Queblo serves as a focal point of trust in the Latino community for workers intending to find the highest quality project and build up their own business. We began in 2015 as a technology company that aimed to connect Latino tradesmen with construction projects in the Twin-Cities while building the Queblo app. We began with the intention of uplifting the construction workers and craftsmen that were hindered by problems endemic to the industry such as wage theft and marginalization. Our app allowed workers to sign up and create a portfolio of their work, thereby leveraging their experience to find better and higher paying projects. Although Queblo no longer functions as a technology platform, we have a commitment to community building and social justice while mainly employing Latino and immigrant groups. We provide new avenues for social capital creation and improve the quality of life for workers, homeowners and business professionals in MN.
    </div>
  </div>
</div>
<div class="project-type" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-around stretch" fxLayoutAlign.lt-md="start stretch" fxLayoutGap.lt-md="20px">
  <div fxFlex="37" class="project-type-description">
    <h2>Terms and Conditions</h2>
    <div class="description-text">
<!--      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temporasd incididunt ut labore sit amet, elit mauris-->
<!--      pharetra an tempor al.-->
    </div>
    <a href="#" routerLink="/terms-and-conditions">VIEW ></a>
  </div>
  <div fxFlex="37" class="project-type-description">
    <h2>Privacy Policy</h2>
    <div class="description-text">
<!--      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temporasd incididunt ut labore sit amet, elit mauris-->
<!--      pharetra an tempor al.-->
    </div>
    <a href="#" routerLink="/privacy-policy">VIEW ></a>
  </div>
</div>
