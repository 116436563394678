import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'queblo-thank-you-card',
  templateUrl: './thank-you-card.component.html',
  styleUrls: ['./thank-you-card.component.scss']
})
export class ThankYouCardComponent implements OnInit {
  @Output() onClose = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }

  close(): void {
    this.onClose.emit();
  }

}
