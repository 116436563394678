import { Component, OnInit } from '@angular/core';
import {ContactService} from '../../service/contact.service';

@Component({
  selector: 'queblo-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  isContactSubmitted = false;
  name = '';
  email = '';
  message = '';
  constructor(private contactService: ContactService) { }

  ngOnInit(): void {
  }

  submitContactForm(): void {
    const name = this.name;
    const email = this.email;
    const message = this.message;
    this.contactService.sendContactData({
      name,
      email,
      message
    }).subscribe(result => {
      console.log(result);
      this.name = '';
      this.email = '';
      this.message = '';
      this.isContactSubmitted = true;
    });
  }
}
