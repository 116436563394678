import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {aboutRoutePrefix} from './constants';
import {ContactService} from './service/contact.service';
import {MatDialog} from '@angular/material/dialog';
import {MobileMenuComponent} from './mobile-menu/mobile-menu.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'queblo';
  isContactSubmitted = false;
  links = [
    {title: 'Commercial', link: 'projects/commercial'},
    {title: 'Residential', link: 'projects/residential'},
    {title: 'Restoration', link: 'projects/restoration'},
    {title: 'Design | Build', link: 'projects/design'}
  ];
  aboutActive = false;

  name = '';
  email = '';
  message = '';

  constructor(private router: Router, private contactService: ContactService, public dialog: MatDialog) {

  }

  ngOnInit(): void {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(
      (e: NavigationEnd) => {
        console.log(e);
        this.aboutActive = e.url.startsWith(`/${aboutRoutePrefix}`);
      }
    );
  }

  openMobileMenu(): void {
    this.dialog.open(MobileMenuComponent, {
      width: '100%',
      height: '100%',
      maxHeight: '100%',
      maxWidth: '100%'
    });
  }

  submitContactForm(): void {
    const name = this.name;
    const email = this.email;
    const message = this.message;
    this.contactService.sendContactData({
      name,
      email,
      message
    }).subscribe(result => {
      console.log(result);
      this.name = '';
      this.email = '';
      this.message = '';
      this.isContactSubmitted = true;
    });
  }
}
