<div fxLayout="row" fxLayoutAlign="start center" style="padding: 10px;position: absolute;margin-top: 15px;">
  <button mat-icon-button (click)="closeWindow()">
    <mat-icon>close</mat-icon>
  </button>
</div>
<div fxLayout="row" fxLayoutAlign="center center" style="padding: 20px;">
  <a routerLink="/">
    <img src="../../assets/images/logoWhite.svg" height="50px"/>
  </a>
</div>
<div fxLayout="row" fxLayoutAlign="center">
  <a
    matRipple
    matRippleColor="#FFFFFF22"
    [routerLink]="['/projects/commercial']"
    routerLinkActive="current-link"
    (click)="closeWindow()"
  >
    Commercial
  </a>
</div>
<div fxLayout="row" fxLayoutAlign="center">
  <a
    matRipple
    matRippleColor="#FFFFFF22"
    routerLink="/projects/design"
    routerLinkActive="current-link"
    (click)="closeWindow()"
  >
    Design | Build
  </a>
</div>
<div fxLayout="row" fxLayoutAlign="center">
  <a
    matRipple
    matRippleColor="#FFFFFF22"
    routerLink="/projects/residential"
    routerLinkActive="current-link"
    (click)="closeWindow()"
  >
    Residential
  </a>
</div>
<div fxLayout="row" fxLayoutAlign="center">
  <a
    matRipple
    matRippleColor="#FFFFFF22"
    routerLink="/projects/restoration"
    routerLinkActive="current-link"
    (click)="closeWindow()"
  >
    Restoration
  </a>
</div>
<div fxLayout="row" fxLayoutAlign="center">
  <a
    matRipple
    matRippleColor="#FFFFFF22"
    routerLink="/about/"
    routerLinkActive="current-link"
    (click)="closeWindow()"
  >
    About
  </a>
</div>
<div fxLayout="row" fxLayoutAlign="center">
  <a
    matRipple
    matRippleColor="#FFFFFF22"
    routerLink="/about/our-team"
    routerLinkActive="current-link"
    (click)="closeWindow()"
    class="secondary"
  >
    Our Team
  </a>
</div>
<div fxLayout="row" fxLayoutAlign="center">
  <a
    matRipple
    matRippleColor="#FFFFFF22"
    routerLink="/about/our-craftsmen"
    routerLinkActive="current-link"
    (click)="closeWindow()"
    class="secondary"
  >
    Our Craftsmen
  </a>
</div>
<div fxLayout="row" fxLayoutAlign="center">
  <a
    matRipple
    matRippleColor="#FFFFFF22"
    routerLink="/about/contact-us"
    routerLinkActive="current-link"
    (click)="closeWindow()"
    class="secondary"
  >
    Contact Us
  </a>
</div>
<div fxLayout="row" fxLayoutAlign="center">
  <a
    matRipple
    matRippleColor="#FFFFFF22"
    routerLink="/about/news"
    routerLinkActive="current-link"
    (click)="closeWindow()"
    class="secondary"
  >
    News
  </a>
</div>
