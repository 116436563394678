<div fxLayout="row" fxLayoutAlign="start center" style="padding: 20px;position: absolute;" fxHide.gt-sm="true">
  <button mat-icon-button (click)="openMobileMenu()">
    <mat-icon>menu</mat-icon>
  </button>
</div>
<div fxLayout="row" fxLayoutAlign="center center" style="padding: 20px;">
  <a routerLink="/">
    <img src="../assets/images/logo.png" height="50px"/>
  </a>
</div>
<nav mat-tab-nav-bar class="navigation-tabs" mat-align-tabs="center" fxHide.lt-md="true">
  <a
    mat-tab-link
    matRipple
    matRippleColor="#A0223922"
    *ngFor="let link of links"
    [routerLink]="['/'+link.link]"
    routerLinkActive
    #rla="routerLinkActive"
    [active]="rla.isActive"
  >
    {{link.title}}
  </a>
  <div
    class="tab-with-arrows"
    mat-tab-link
    matRipple
    matRippleColor="#A0223922"
    [matMenuTriggerFor]="menu"
    (click)="$event.stopPropagation()"
    [active]="aboutActive"
  >
    About<mat-icon class="arrow-down">arrow_drop_down</mat-icon><mat-icon class="arrow-up">arrow_drop_up</mat-icon>
  </div>
  <mat-menu #menu="matMenu">
    <button mat-menu-item [routerLink]="['/about/']" matRipple
            matRippleColor="#A0223922">About</button>
    <button mat-menu-item [routerLink]="['/about/our-team']" matRipple
            matRippleColor="#A0223922">Our Team</button>
    <button mat-menu-item [routerLink]="['/about/our-craftsmen']" matRipple
            matRippleColor="#A0223922">Our Craftsmen</button>
    <button mat-menu-item [routerLink]="['/about/contact-us']" matRipple
            matRippleColor="#A0223922">Contact Us</button>
    <button mat-menu-item [routerLink]="['/about/news']" matRipple
            matRippleColor="#A0223922">News</button>
  </mat-menu>
</nav>
<router-outlet></router-outlet>
<div class="contact-info ">
  <div class="header" fxLayout.gt-sm="row" fxLayout.lt-md="column" fxLayoutAlign.gt-sm="space-between center" fxLayoutAlign.lt-md="start stretch">
    <div fxFlex="20">
      <img src="../assets/images/footer/quebloLogoSmall.svg"/>
    </div>
    <div class="page-links navigation-tabs" mat-tab-nav-bar fxFlex="55" fxHide.lt-lg="" fxLayout="row wrap" fxLayoutAlign="center center">
      <a
        class="page-link"
        mat-tab-link
        matRipple
        matRippleColor="#4B4B4B22"
        *ngFor="let link of links"
        [routerLink]="['/'+link.link]"
        routerLinkActive
        #rla="routerLinkActive"
        [active]="rla.isActive"
      >
        {{link.title}}
      </a>
      <div
        class="tab-with-arrows page-link"
        mat-tab-link
        matRipple
        matRippleColor="#4B4B4B22"
        [matMenuTriggerFor]="bottomAboutMenu"
        (click)="$event.stopPropagation()"
        [active]="aboutActive"
      >
        About<mat-icon class="arrow-down">arrow_drop_down</mat-icon><mat-icon class="arrow-up">arrow_drop_up</mat-icon>
      </div>
      <mat-menu #bottomAboutMenu="matMenu">
        <button mat-menu-item [routerLink]="['/about/']" matRipple
                matRippleColor="#A0223922">About</button>
        <button mat-menu-item [routerLink]="['/about/our-team']" matRipple
                matRippleColor="#A0223922">Our Team</button>
        <button mat-menu-item [routerLink]="['/about/our-craftsmen']" matRipple
                matRippleColor="#A0223922">Our Craftsmen</button>
        <button mat-menu-item [routerLink]="['/about/contact-us']" matRipple
                matRippleColor="#A0223922">Contact Us</button>
        <button mat-menu-item [routerLink]="['/about/news']" matRipple
                matRippleColor="#A0223922">News</button>
      </mat-menu>
    </div>
    <div class="get-in-touch-form-heading" fxFlex="25" fxHide.lt-md="">GET IN TOUCH</div>
  </div>
  <div class="content" fxLayout.gt-sm="row" fxLayout.lt-md="column" fxLayoutAlign.gt-sm="space-between start" fxLayoutAlign.lt-md="start stretch">
    <div class="contacts" fxFlex="20">
      <div class="address">1515 East Lake Street</div>
      <div class="address">Minneapolis, MN 55407</div>
      <div class="phone"><span class="label">Phone</span> (612) 208-2729</div>
      <div class="email"><span class="label">Email</span> info@queblo.com</div>
    </div>
    <div fxFlex="45"></div>
    <div class="get-in-touch-form-heading" fxFlex="25" fxHide.gt-sm="">GET IN TOUCH</div>
    <div class="get-in-touch-form" fxFlex="25" *ngIf="!isContactSubmitted">
      <mat-form-field class="full-width">
        <mat-label>Your Name</mat-label>
        <input type="text" matInput [(ngModel)]="name"/>
      </mat-form-field>
      <mat-form-field class="full-width">
        <mat-label>Your Email</mat-label>
        <input type="email" matInput [(ngModel)]="email"/>
      </mat-form-field>
      <mat-form-field class="full-width">
        <mat-label>Message</mat-label>
        <input #messageInput type="text" matInput maxlength="50" [(ngModel)]="message" />
        <mat-hint align="end">{{messageInput.value.length}} / 50</mat-hint>
      </mat-form-field>
      <button mat-raised-button color="accent" class="submit-button" (click)="submitContactForm()">Submit</button>
    </div>
    <div class="get-in-touch-form" fxFlex="25" *ngIf="isContactSubmitted">
      <queblo-thank-you-card (onClose)="isContactSubmitted = false;"></queblo-thank-you-card>
    </div>
  </div>
</div>
<div class="footer">
  <div class="social-links" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="25px">
    <a href="https://www.facebook.com/queblo"><img src="../assets/images/footer/facebook.svg"/></a>
    <a href="https://twitter.com/QuebloInc"><img src="../assets/images/footer/twitter.svg"/></a>
    <a href="https://instagram.com/queblo.construct"><img src="../assets/images/footer/instagram.svg"/></a>
  </div>
  <div class="copyright-text">© 2024 Queblo. All Rights Reserved</div>
</div>
