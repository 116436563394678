import { Component, OnInit } from '@angular/core';
import people from '../../data/people.json';
import {ContactService} from '../../service/contact.service';

@Component({
  selector: 'queblo-our-craftsmen',
  templateUrl: './our-craftsmen.component.html',
  styleUrls: ['./our-craftsmen.component.scss']
})
export class OurCraftsmenComponent implements OnInit {
  isContactSubmitted = false;
  people = people.craftsmen;

  firstName = '';
  lastName = '';
  email = '';
  phone = '';
  constructor(private contactService: ContactService) { }

  ngOnInit(): void {
  }

  submitContactForm(): void {
    const firstName = this.firstName;
    const lastName = this.lastName;
    const email = this.email;
    const phone = this.phone;
    this.contactService.sendContactData({
      firstName,
      lastName,
      email,
      phone
    }).subscribe(result => {
      console.log(result);
      this.firstName = '';
      this.lastName = '';
      this.email = '';
      this.phone = '';
      this.isContactSubmitted = true;
    });
  }

}
