import {Component, Input, OnInit} from '@angular/core';
import {Craftsmen} from './craftsmen';

@Component({
  selector: 'queblo-craftsmen-card',
  templateUrl: './craftsmen-card.component.html',
  styleUrls: ['./craftsmen-card.component.scss']
})
export class CraftsmenCardComponent implements OnInit {
  @Input() person: Craftsmen;

  constructor() { }

  ngOnInit(): void {
  }

}
