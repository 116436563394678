<div fxLayout="row" fxLayoutAlign="center start">
  <h2>Terms of Use</h2>
</div>
<div class="full-width">
  Welcome to the queblo.com web site (the “Site”). The Site is a platform for registered workers (“Workers”) to find
  registered contractors (“Contractors”) looking to contract with or employ Workers for temporary or permanent work
  opportunities. The Site is owned and operated by Queblo, Inc., a Minnesota corporation (“Queblo”). “You” or “your” in
  these Terms of Use refer to any user of the Site, including any Worker or Contractor, and in the case of a Contractor,
  “you” or “your” also refers to the entity for whom you work. “We”, “our”, or “Queblo” refers to Queblo, Inc. By
  accessing and using the Site you agree to be bound by these Terms of Use. Use of the Site is strictly voluntary. If
  you do not agree to these Terms of Use and the Privacy Policy (the “Privacy Policy”), you must immediately log off the
  Site and may not use the Site. These Terms of Use are a binding contract between you and Queblo. These Terms of Use
  apply to your access to and use of the Site and do not alter in any way the terms and conditions of any other
  agreement you may have with Queblo, unless otherwise directed by Queblo. If you breach any of these terms and
  conditions, your authorization to use the Site automatically terminates and you must immediately discontinue use of
  the Site.
</div>

<h3>1. Collection of Information</h3>
<div class="full-width">
  Any information collected through the Site is governed by our Privacy Policy, unless otherwise stated. To access and
  use certain services available through the Site, you may be required to register with Queblo through a
  login/registration page and to provide certain personal information through the Site, and you must agree to be bound
  by any additional applicable terms and conditions contained there.
</div>

<h3>2. Copyright</h3>
<div class="full-width">
  All content, software, and technology included on the Site or used in the operation of the Site is the owned or
  licensed property of Queblo or its content, software, and technology suppliers, and is protected by United States and
  international copyright laws. The compilation (meaning the collection, arrangement, and assembly) of all content on
  the Site is the exclusive property of Queblo and protected by United States and international copyright laws. Queblo
  grants you permission to view and use content, software, and technology made available to you on the Site in
  connection with your own personal, noncommercial (except as contemplated herein) use of the Site. Any other use,
  including without limitation, the reproduction, modification, distribution, transmission, republication, display, or
  performance, of the content, software, and technology on the Site, is strictly prohibited.
</div>

<h3>3. Trademarks</h3>
<div class="full-width">
  The trademarks, service marks, trade dress, and “look and feel” (collectively, the “Trademarks”) used and displayed on
  the Site are registered and unregistered Trademarks of Queblo and others. Nothing on the Site shall be construed as
  granting any license or right to use any Trademark displayed on the Site without the prior written permission of the
  Trademark owner. Other product and company names mentioned in the Site may be the Trademarks of their respective
  owners.
</div>

<h3>4. Other Intellectual Property</h3>
<div class="full-width">
  All other intellectual property rights related to the content, software, and technology included on the Site or used
  in the operation of the Site, including without limitation, patents, trade secrets, trade dress, publicity rights,
  character rights, titles, and artistic and moral rights are the owned or licensed property of Queblo. Any use of these
  rights without the prior written permission of Queblo is strictly prohibited.
</div>
<h3>5. Your Account</h3>
<div class="full-width">
  To use the Site, you must register as either a Worker or Contractor. To register as a Worker or Contractor on the
  Site, you must provide all required information requested on the Site. To register as a Worker on and to use the Site,
  you must be, and you represent and warrant to us that, (a) you are a legal resident of the United States or one of its
  territories; (b) you are legally authorized to work in the United States; (c) you are licensed to conduct the business
  activities and work you indicate on the Site in any jurisdiction requiring you to be licensed; and (d) you are an
  individual at least 18 years old at the time of registering on the Site. To register as a Contractor on and to use the
  Site, you must be, and you represent and warrant to us that, (a) you are an organization, company, or other entity
  lawfully organized or incorporated and operating in the United States; (b) you are in good standing in the
  jurisdiction of organization or incorporation and in every other jurisdiction where you conduct business; and (c) you
  are licensed to conduct the business activities and work you indicate on the Site in any jurisdiction requiring you to
  be licensed. The individual registering on behalf of the Contractor represents and warrants to us that the individual
  has full right and authority to register the Contractor on the Site and to perform the Contractor’s obligations
  thereunder, all required approvals and authorizations have been obtained, and the Contractor’s registration and use of
  the Site complies with all policies, whether written or oral, of the Contractor and all laws, rules, and regulations.
  If you are a registered user of the Site, you are responsible for maintaining the confidentiality of your account and
  password and for restricting access to your computer. You agree to accept responsibility for all activities that occur
  under your account or password. By using the Site, you agree to: (a) provide true, accurate, current, and complete
  information about you; (b) maintain and promptly update such information to keep it true, accurate, current, and
  complete; and (c) notify us of any security breach or unauthorized use of your account or password. Queblo has the
  right, without notice and in its sole discretion, to suspend or terminate your access to the Site and refuse any and
  all current or future use of the Site (or any portion thereof) and to remove or edit content on the Site.
</div>

<h3>6. Interactions with Others</h3>
<div class="full-width">
  Contractors may search for and respond to Workers on the Site. We reserve the right to charge a fee for searching
  Workers at any time for any reason. CONTRACTORS ARE SOLELY RESPONSIBLE FOR VERIFYING THE QUALIFICATIONS, LICENSURE,
  QUALITY, AND AUTHORIZATION TO WORK IN THE UNITED STATES OF ANY WORKERS, AND FOR OBTAINING ANY REQUIRED WORK
  AUTHORIZATION DOCUMENTATION. Workers may search for and respond to Contractors on the Site. We reserve the right to
  charge a fee for searching Contractors at any time for any reason. WORKERS ARE SOLELY RESPONSIBLE FOR VERIFYING THE
  QUALIFICATIONS, LICENSURE, AND QUALITY OF ANY CONTRACTORS. Your correspondence, interactions, or business dealings
  with parties found on or through the Site, including payment and delivery of related goods or services, and any other
  terms, conditions, warranties, or representations associated with such dealings, are solely between you and such
  parties. Queblo shall not be responsible or liable for any claims, loss, or damage of any sort incurred as the result
  of any such dealings or as the result of use of the Site. It is your responsibility to make whatever investigation you
  feel necessary or appropriate before proceeding with any online or offline dealings with any parties and to verify the
  qualifications or working environment of any Worker or Contractor, or any information posted on the Site. Queblo does
  not, and has no obligation to, investigate any Worker or Contractor or any information provided by Workers or
  Contractors. Queblo disclaims any liability with respect to such content and dealings. If there is a dispute between
  you and any such party, you understand and agree that Queblo is under no obligation to become involved. Without
  limiting the foregoing, Contractors are solely responsible for their accounts, and Workers are solely responsible for
  their accounts. WE DO NOT EMPLOY ANY WORKERS, NOR DO WE ENDORSE, REFER, OR RECOMMEND ANY WORKERS OR CONTRACTORS.
</div>

<h3>7. Prohibited Activities and Obligations</h3>
<div class="full-width">
  As a visitor or user of the Site you shall not, and may not use the Site to, directly or indirectly:  violate any
  applicable law, rule, or regulation;  upload, post, use, transmit, or otherwise make available (each, a “Post”) any
  content or information that is false or inaccurate;  Post any content that is unlawful, harmful, threatening,
  abusive, harassing, tortious, defamatory, vulgar, obscene, libelous, invasive of another’s privacy, or content that
  could otherwise be considered to be objectionable;


  Use the Site to disseminate any information or content, including by e-mail or offline communications, that is
  unlawful, harmful, threatening, abusive, harassing, tortious, defamatory, vulgar, obscene, libelous, invasive of
  another’s privacy, or information or content that could otherwise be considered to be objectionable; impersonate or
  use the identity of another person or organization, or falsely state or otherwise misrepresent your affiliation with a
  person or organization; falsely state or otherwise misrepresent information about you; engage in any activities or
  manipulate identifying material to misrepresent the origin of content; Post any content that is subject to any
  disclosure or confidentiality restrictions; Post any materials, items, information, or content that infringes or
  otherwise violates any patent, copyright, trademark, or any other intellectual property rights of any party, or any
  laws, rules, or regulations; Post any unsolicited or unauthorized advertising or promotional materials, including
  without limitation, “junk mail,” “spam,” “chain letters,” “pyramid schemes,” or any other form of solicitation;
  interfere with or otherwise limit the use of the Site by other users, or collect, compile, or store personal
  information about other users of the Site; Post any material that contains software viruses or any other corrupt
  computer code, files, or programs that impair the use of any computer software or hardware or telecommunications
  equipment or the Site; disrupt or interfere with the operation of the Site by overloading or exceeding the capacity of
  the Site or the systems resources; accounts; usernames, and passwords; servers; or networks connected to or accessible
  through the Site or any affiliated or linked sites; disrupt or interfere with the security of, or otherwise cause harm
  to, the Site or the systems resources; accounts; usernames and passwords; servers; or networks connected to or
  accessible through the Site or any affiliated or linked sites; use any robot, spider, other automatic device, or
  manual process to “screen scrape,” monitor, “mine,” or copy the web pages on the Site or the content contained therein
  without Queblo’s prior written permission; or use the Site for commercial purposes (except as contemplated herein).
  You agree that you are solely responsible for the Posting of any content that you make available on or send through
  the Site (“User Content”). In addition to the rights granted to Queblo elsewhere in these Terms of Use, you hereby
  grant Queblo the irrevocable right to copy and use User Content in connection with the Site and for the purposes
  related to your Post of the User Content and to provide the services under the Site. We do not endorse any User
  Content submitted to the Site, or endorse any opinion, recommendation, or advice expressed therein. We expressly
  disclaim any and all liability in connection with User Content. Although Queblo has no obligation to screen, edit, or
  monitor any of the User Content, accounts, or user conduct on the Site, Queblo reserves the right, and has absolute
  discretion, to remove, screen, or edit any User Content, accounts, and to monitor any user conduct on the Site at any
  time and for any reason without notice. Your User Content may be available on the Site for a limited period of time,
  as determined by Queblo in its sole discretion. You are solely responsible for creating backup copies and replacing
  any of your User Content at your sole cost and expense. By the Posting of User Content to the Site, you represent and
  warrant that (a) the User Content is non-confidential is not subject to disclosure restrictions under any applicable
  data privacy laws, rules, or regulations; (b) you have all necessary rights to Post the User Content to the Site and
  to grant the rights to Queblo you grant in these Terms of Use; (c) the User Content is accurate and not misleading or
  harmful in any manner; and (d) the User Content, and the use and the Post thereof in connection with the Site, does
  not and will not violate these Terms of Use or any law, rule, or regulation.
</div>

<h3>8. Disclaimer of Warranties and Release</h3>
<div class="full-width">
  THE SITE AND ALL CONTENT, MATERIALS, PRODUCTS, AND SERVICES AVAILABLE THROUGH THE SITE ARE PROVIDED ON AN “AS IS, WITH
  ALL DEFECTS” AND “AS AVAILABLE” BASIS WITHOUT WARRANTIES OF ANY KIND, EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE.
  QUEBLO DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION, WARRANTIES OF TITLE,
  NON-INFRINGEMENT, AND IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND ANY WARRANTIES
  ARISING OUT OF ANY COURSE OF DEALING OR USAGE OF TRADE. QUEBLO MAKES NO REPRESENTATION THAT THE SITE OR ANY CONTENT IS
  FREE FROM ERRORS, VIRUSES, BUGS, OR OTHER HARMFUL COMPONENTS. QUEBLO MAKES NO REPRESENTATION OR WARRANTY REGARDING ANY
  WORKER’S OR CONTRACTOR’S QUALIFICATIONS, AUTHORIZATION TO WORK, LICENSURE, OR OTHER INFORMATION, OR THAT CONTENT
  PROVIDED ON THE SITE IS APPLICABLE OR APPROPRIATE FOR USE IN LOCATIONS OUTSIDE OF THE UNITED STATES. NOTHING IN THESE
  TERMS OF USE OR IN ANY ORAL OR WRITTEN DESCRIPTION OF THE SITE WILL CONSTITUTE A REPRESENTATION OR WARRANTY WITH
  RESPECT TO THE SITE. YOU ACKNOWLEDGE, BY YOUR USE OF THE SITE, THAT SUCH USE IS AT YOUR SOLE RISK. QUEBLO IS NOT
  RESPONSIBLE FOR THE ACTIONS, CONTENT, INFORMATION, OR DATA OF PARTIES OTHER THAN QUEBLO. YOU HEREBY RELEASE QUEBLO,
  AND ITS AFFILIATES, DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, AND SUCCESSORS, FROM ANY CLAIMS, DEMANDS, AND DAMAGES
  (ACTUAL AND CONSEQUENTIAL) OF EVERY KIND OR NATURE, KNOWN OR UNKNOWN, SUSPECTED AND UNSUSPECTED, DISCLOSED AND
  UNDISCLOSED, ARISING OUT OF OR IN ANY WAY RELATED TO YOUR USE OF THE SITE. IF YOU ARE A CALIFORNIA RESIDENT, YOU WAIVE
  THE PROTECTIONS OF CALIFORNIA CIVIL CODE SECTION 1542, WHICH SAYS: “A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH
  THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF EXECUTING THE RELEASE, WHICH, IF
  KNOWN BY HIM OR HER MUST HAVE MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH THE DEBTOR.”
</div>

<h3>9. Limitation of Liability</h3>
<div class="full-width">
  IN NO EVENT SHALL QUEBLO, OR ITS AFFILIATES, DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, AND SUCCESSORS, BE LIABLE FOR ANY
  DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, OR CONSEQUENTIAL DAMAGES ARISING OUT OF OR IN ANY WAY CONNECTED WITH
  THE USE OF THE SITE OR THE PRODUCTS OR SERVICES AVAILABLE THROUGH THE SITE, WHETHER BASED ON CONTRACT, TORT, WARRANTY,
  STRICT LIABILITY, OR OTHERWISE, EVEN IF ADVISED OF THE POSSIBILITY OF ANY SUCH DAMAGES. Under no circumstances
  whatsoever shall Queblo’s aggregate liability resulting from or relating to your use of the Site exceed Five Dollars
  ($5.00). Regardless of any law, rule, or regulation to the contrary, any claim or cause of action you may have that
  arises out of or is related to use of this Site must be filed within one (1) year after such claim or cause of action
  arose or be forever barred. Certain state laws do not allow limitations on implied warranties or the exclusion or
  limitation of certain damages. If these laws apply to you, some or all of the above disclaimers, exclusions, or
  limitations may not apply to you, and you may have additional rights.
</div>

<h3>10. Indemnification</h3>
<div class="full-width">
  You agree to indemnify and hold harmless Queblo, and its affiliates, directors, officers, employees, agents, and
  successors, from and against and in respect of any and all claims, demands, losses, costs, expenses, obligations,
  liabilities, damages, recoveries, and deficiencies, including without limitation, interest, penalties, and reasonable
  attorneys’ fees, that Queblo incurs or suffers which relate to your use of the Site, your failure to perform any of
  your obligations under these Terms of Use, your breach of any of your representations or warranties made under these
  Terms of Use, or your interactions with any other user of the Site, including without limitation, your hiring,
  employment, or contracting with any Worker or Contractor, any workers compensation claim or injury, and any payment or
  failure to pay for any goods or services.
</div>

<h3>11. Submissions</h3>
<div class="full-width">
  Any and all questions, comments, suggestions, and similar materials or information that you send or submit to Queblo
  or a third party provider on the Site, by a Post or otherwise (collectively, “Submissions”), shall become Queblo's
  property; and you hereby transfer, sell, and assign to Queblo all of your right, title, and interest in and to any
  such Submissions, including without limitation, any and all related patent, copyright, trademark, and other
  intellectual property rights.

  Queblo has no obligation of any kind whatsoever to maintain any confidentiality with respect to any such Submissions.
  Queblo shall be free to use them for any purpose whatsoever without providing you notice or receiving your consent,
  and without restriction or compensation.
</div>
<h3>12. Accuracy</h3>
<div class="full-width">
  Information on the Site may contain typographical errors, inaccuracies, or omissions in relation to services,
  locations, descriptions, information, pricing, and other matters. Queblo reserves the right to correct any errors,
  inaccuracies, or omissions and to discontinue, change, or update information at any time without prior notice.
</div>
<h3>13. Links to Other Web Sites</h3>
<div class="full-width">
  The Site may contain links to other web sites. Additionally, other web sites may contain links to the Site. Queblo
  does not review or monitor the web sites linked to the Site and Queblo is not responsible for the content or policies
  of any other web sites. Any link established by Queblo does not constitute an endorsement of the other web sites or
  create a relationship between Queblo and the operators of other web sites. Queblo disclaims any liability with respect
  to your use of any other web site, and you release Queblo from any liability related to your use of any link to
  another web site or your use of any other web site. Where the Site contains a link to another web site owned and/or
  operated by Queblo, such website use may be subject to different and additional terms and conditions.
</div>
<h3>14. Changes to Terms of Use</h3>
<div class="full-width">
  Queblo may revise these Terms of Use at any time without your consent or notice to you. Your continued use of the Site
  following the date on which revisions to these Terms of Use are published on the Site shall constitute your acceptance
  of the revised Terms of Use. You expressly agree that Queblo shall have no obligation to provide you with any notice
  of any revisions, and you hereby expressly waive any right you may have to receive notice of any revisions to these
  Terms of Use.
</div>
<h3>15. Continued Operation, Accessibility, and Maintenance of the Site</h3>
<div class="full-width">
  Queblo may modify, change, suspend, terminate, or discontinue part or all of the operation of the Site or the
  provision of any services without notice and for any reason, and Queblo reserves the right to refuse service to anyone
  at any time, with or without cause. You acknowledge that the Site may, from time to time, be down for maintenance or
  updates.
</div>
<h3>16. Assignment</h3>
<div class="full-width">
  You may not assign, delegate, or transfer these Terms of Use or your rights or obligations hereunder, or your account
  for the Site, in any way (by operation of law or otherwise) without Queblo’s prior written consent. Queblo may
  transfer, assign, or delegate these Terms of Use and Queblo’s rights and obligations without your consent or notice to
  you.
</div>
<h3>17. General Provisions</h3>
<div class="full-width">
  The English language version of these Terms of Use and the Privacy Policy shall be controlling in all respects and
  shall prevail to the extent inconsistent with any translated versions, if any. Any failure by Queblo to enforce any
  provision of these Terms of Use shall not be construed as a waiver of any provision or the right to enforce same.
  These Terms of Use shall be governed by the laws of the State of Minnesota without giving effect to any principles or
  conflicts of laws. For the purpose of resolving conflicts relating to or arising out of these Terms of Use, or arising
  out of any other dispute or claim associated with Queblo, you irrevocably and unconditionally: (a) consent to submit
  to the exclusive jurisdiction of the state and federal courts in the State of Minnesota (the “Minnesota Courts”); (b)
  agree not to commence any litigation except in the Minnesota Courts; (c) agree not to plead or claim that such
  litigation brought therein has been brought in an inconvenient forum; and (d) agree the Minnesota Courts represent the
  exclusive jurisdiction for all such disputes and claims. If any portion of these Terms of Use is held to be invalid,
  such holding shall not invalidate the other provisions of these Terms of Use. Use of the Site is unauthorized in any
  jurisdiction that does not give full effect to all provisions of these Terms of Use, including without limitation,
  this Section and the warranty disclaimers and liability exclusions herein. Use of the Site is unauthorized in any
  jurisdiction where all or any portion of the Site may violate any legal requirements and you agree not to access the
  Site in any such jurisdiction. You are responsible for compliance with laws, rules, and regulations.
</div>
<h3>18. Stripe and Plaid</h3>
<div class="full-width">
  By accepting Queblo Terms of Service you are also accepting the Terms and Privacy by Stripe and Plaid which are listed
  here:

  https://stripe.com/us/legal/

  https://plaid.com/legal/#terms-of-use
</div>
<h3>19. Contact</h3>
<div class="full-width">
  For questions or comments related to the Site or these Terms of Use, please email us at info@queblo.com.

  Last updated April 2, 2015.

  4827-0735-5938, v.2
</div>
