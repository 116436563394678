<div fxLayout="row" fxLayoutAlign="center start">
  <h2>Privacy Policy</h2>
</div>
<div class="full-width">
  Queblo, Inc., a Minnesota corporation (“we,” “us,” and “our”), respects the importance of the privacy of your personal
  information. We provide this Privacy Policy so you know about our online information practices and understand how we
  treat the information we receive about you from your use of the queblo.com website (the “Site”). By using, browsing,
  or accessing the Site, you agree to the terms of this Privacy Policy. Your visit to the Site and any dispute over
  privacy is subject to the Terms of Use, including limitations on damages and application of the law of the State of
  Minnesota.

  By accessing and using the Site you agree to be bound by this Privacy Policy. Use of the Site is strictly voluntary.
  If you do not agree to this Privacy Policy and the Terms of Use, you must immediately log off the Site and may not use
  the Site. This Privacy Policy applies to your access to and use of the Site and do not alter in any way the terms and
  conditions of any other agreement you may have with Queblo, unless otherwise directed by Queblo. If you breach any of
  these terms and conditions, your authorization to use the Site automatically terminates and you must immediately
  discontinue use of the Site.
</div>
<h3>1. Your Consent</h3>
<div class="full-width">BY USING THE SITE, YOU CONSENT TO THE COLLECTION AND PROCESSING OF YOUR PERSONAL INFORMATION IN
  THE UNITED STATES AND TO OUR USE OF YOUR PERSONAL INFORMATION AND ANY RELATED INFORMATION IN THE MANNER DESCRIBED IN
  THIS PRIVACY POLICY. IF YOU ARE SUBMITTING PERSONAL INFORMATION OF THIRD PARTIES TO US, YOU WARRANT AND REPRESENT YOU
  HAVE THEIR CONSENT TO PROVIDE THEIR PERSONAL INFORMATION TO US AND YOU WILL INDEMNIFY US AND HOLD US HARMLESS FROM AND
  AGAINST CLAIMS BY SUCH INDIVIDUALS RELATING TO OUR PROCESSING AND USE OF SUCH PERSONAL INFORMATION WITHIN THE TERMS OF
  THIS PRIVACY POLICY.
</div>
<h3>2. The Information We Collect</h3>
<div class="full-width">
  In general, you can browse this Site without telling us who you are or revealing any personal information about
  yourself. However, we collect your computer’s or mobile device’s IP address, which you necessarily disclose when you
  visit the Site, so that data (such as the web pages you request) can be sent to your computer or device. The Site
  utilizes “cookies,” which are text files placed on your computer that web pages on the Site may use to store data on
  your computer or send data to our web server. We may use cookie data to “recognize” you when you visit the Site in the
  future, to troubleshoot how the Site operates, and for internal analysis of how people use the Site. Should you decide
  not to accept cookies from the Site, you will limit the functionality we can provide when you visit and use the Site.
  We may use standard Internet technology, such as web beacons and other similar technologies, to track your use of the
  Site.

  When you engage in certain activities on the Site, we may ask you to provide personal and other information. In order
  to engage in such activities you will be required to provide information that lets us know the specifics of who you
  are, such as your name, e-mail address, address and other contact information, qualifications, rates and availability,
  and other information. The information we collect will depend upon the activity; it is optional for you to engage in
  these activities.

  Depending upon the activity, some of the information we ask you to provide is identified as mandatory and some
  voluntary. If you do not provide the mandatory information for a particular activity, you will not be able to engage
  in that activity or you limit your ability to use all or a portion of the Site.
</div>
<h3>3. The Way We Use Information</h3>
<div class="full-width">
  We may use your IP address to, among other things: (a) help diagnose problems with the Site, administer and improve
  the Site, and measure the use of the Site; (b) identify the type of web browsers used, the pages viewed, the web sites
  from which a visitor found this Site, and the search terms and search engines used to find this Site; and (c) gather
  other web analytics information relating to visitors’ use and navigation of the Site. This information does not
  identify you personally. On occasion we may aggregate this data with data on the pages visited by other users to track
  overall visitor traffic patterns and provide this aggregate information to vendors, business partners, affiliates, or
  other parties. The information we obtain using standard Internet technology, such as web beacons and other similar
  technologies, enables us to customize the services we offer visitors to our Site, to deliver targeted content and
  advertisements, and to measure the overall effectiveness of our online content, advertising, programming, or other
  activities. We may always use the information you provide in an anonymous or aggregated format that does not identify
  you personally, which uses might include without limitation, to evaluate existing products, services, and systems, to
  assist in the development of new products or services, research, advertising, and to identify trends and changes.

  We may use your personal information for particular activities, including without limitation, customer service,
  marketing and research, developing and displaying content and advertising tailored to your interests, providing
  product, promotional, and other information to you, and to contact you regarding administrative notices. We may share
  this information with marketing partners, advertisers, and other parties with whom we collaborate. In addition, if we
  use service providers, these service providers may have access to your personal information to perform contractually
  specified services on our behalf. Your personal information may also be accessed or used by other users of the Site.

  We may disclose personal information in any of the following circumstances: (a) in response to a subpoena, search
  warrant, court order, or other legal process or request, or as may otherwise be required by law, rule, or regulation;
  (b) to protect and defend our rights or property or those of its affiliates or business partners, or others; or (c) as
  we, in our sole discretion, believe is appropriate under exigent circumstances in response to a perceived threat to
  the personal safety, property, or rights of any person or organization. In addition, since our database is a business
  asset, in the event we are reorganized or become part of another organization, you consent to the sale, assignment, or
  transfer of your personal information to a successor that acquires substantially all of our business assets, or to an
  affiliate, as applicable.
</div>
<h3>4. Our Relationship to Other Web Sites</h3>
<div class="full-width">
  This Site may contain links to other web sites. If you visit one of these other web sites, we encourage you to review
  the privacy policy on that web site.
</div>
<h3>5. Security of Your Personal Information</h3>
<div class="full-width">
  We take what we believe to be reasonable precautions and have implemented certain safeguards to protect the security
  of data and personal information. However, we cannot guarantee against any loss, misuse, unauthorized disclosure, or
  alteration or destruction of data or personal information. While we strive to protect your personal information, we
  cannot guarantee the security of any information you transmit to or from this Site and you provide such information at
  your own risk. You acknowledge that: (a) there are security and privacy limitations in computer systems and on the
  Internet which are beyond our control; (b) the security, integrity, and privacy of any and all information and data
  exchanged between you and us through the Site, including personal information, cannot be guaranteed; and (c) any such
  information and data may be viewed or tampered with by a third party while such information or data is being used,
  transmitted, processed, or stored.
</div>
<h3>6. Changes to Privacy Policy</h3>
<div class="full-width">
  We reserve the right to revise this Privacy Policy at any time without notice. You expressly waive any right to
  receive notice of any revisions to this Privacy Policy. We encourage you to review this Privacy Policy regularly in
  your use of the Site to ensure you are aware of current practices. Your continued use of the Site following the date
  on which revisions to this Privacy Policy are published on the Site shall constitute your acceptance of all the
  revised Privacy Policy.
</div>
<h3>7. Contact</h3>
<div class="full-width">
  For questions or comments related to the Site or this Privacy Policy, please email us at info@queblo.com

  Last updated April 2, 2015.

  4818-3605-5842, v.2
</div>
