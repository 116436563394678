import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LandingComponent} from './landing/landing.component';
import {AboutComponent} from './about/about.component';
import {ProjectsListComponent} from './projects-list/projects-list.component';
import {InfoComponent} from './about/info/info.component';
import {OurTeamComponent} from './about/our-team/our-team.component';
import {OurCraftsmenComponent} from './about/our-craftsmen/our-craftsmen.component';
import {ContactUsComponent} from './about/contact-us/contact-us.component';
import {NewsComponent} from './about/news/news.component';
import { aboutRoutePrefix } from './constants';
import {TermsOfUseComponent} from './terms-of-use/terms-of-use.component';
import {PrivacyPolicyComponent} from './privacy-policy/privacy-policy.component';

const routes: Routes = [
  {path: 'projects/:type', component: ProjectsListComponent},
  {
    path: aboutRoutePrefix, component: AboutComponent,
    children: [
      { path: '', component: InfoComponent },
      { path: 'our-team', component: OurTeamComponent },
      { path: 'our-craftsmen', component: OurCraftsmenComponent },
      { path: 'contact-us', component: ContactUsComponent },
      { path: 'news', component: NewsComponent }
    ]
  },
  {path: 'terms-and-conditions', component: TermsOfUseComponent},
  {path: 'privacy-policy', component: PrivacyPolicyComponent},
  {path: '**', component: LandingComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
