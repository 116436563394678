<div class="full-width person-image-block">
  <img src="../../assets/images/team/{{person.imageURL}}" />
  <div class="person-image-label" fxLayout="column" fxLayoutAlign="start center">
    <h2>{{person.name}}</h2>
    <div class="description-text">{{person.title}}</div>
  </div>
</div>
<h2 class="heading">{{person.name}}</h2>
<div class="description-text">
  {{person.description}}
</div>
