<mat-card class="mat-elevation-z5 person-card">
  <div
    fxLayout.gt-sm="row"
    fxLayout.lt-md="column"
    fxLayoutAlign.gt-sm="start start"
    fxLayoutAlign.lt-md="start center"
    fxLayoutGap="25px"
  >
    <div class="person-image-block">
      <img src="../../assets/images/team/{{person.imageURL}}"/>
      <div class="person-image-label" fxLayout="column" fxLayoutAlign="start center">
        <h2>{{person.name}}</h2>
        <div class="description-text">{{person.title}}</div>
      </div>
    </div>
    <div fxLayout.lt-md="column" fxLayoutAlign.lt-md="start center">
      <h2 class="heading">{{person.name}}</h2>
      <div class="description-text" [innerHTML]="person.description">
      </div>
    </div>
  </div>
  <div *ngIf="person.descriptionBottom" class="description-text description-text-bottom">
    {{person.descriptionBottom}}
  </div>
</mat-card>
