<div class="container contact-info" fxLayout="row" fxLayoutAlign="space-between start" fxLayout.lt-md="column" fxLayoutAlign.lt-md="start stretch">
  <div fxFlex="33">
    <h1>Contact Us</h1>
    <div class="description-text">
      Get in touch with us to start your construction project.
    </div>
  </div>
  <mat-card fxFlex="60" class="contact-us-card">
    <h1>Contact Us</h1>
    <div *ngIf="!isContactSubmitted" class="inputs-container">
      <mat-form-field class="full-width">
        <mat-label>Your Name</mat-label>
        <input type="text" matInput [(ngModel)]="name"/>
      </mat-form-field>
      <mat-form-field class="full-width">
        <mat-label>Email</mat-label>
        <input type="email" matInput [(ngModel)]="email"/>
      </mat-form-field>
      <mat-form-field class="full-width">
        <mat-label>Message</mat-label>
        <textarea matInput #messageInput maxlength="150" [(ngModel)]="message"></textarea>
        <mat-hint align="end">{{messageInput.value.length}} / 150</mat-hint>
      </mat-form-field>
    </div>
    <button *ngIf="!isContactSubmitted" mat-fab color="accent" class="submit-button" (click)="submitContactForm()"><mat-icon>send</mat-icon></button>
    <queblo-thank-you-card *ngIf="isContactSubmitted" (onClose)="isContactSubmitted = false;"></queblo-thank-you-card>
  </mat-card>
</div>
