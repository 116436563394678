<mat-tab-group
  class="image-gallery landing-page-taglines"
  headerPosition="below"
  mat-align-tabs="center"
  [selectedIndex]="selectedSlideIndex"
>
  <mat-tab>
    <ng-template mat-tab-label>
      <div class="dot"></div>
    </ng-template>
    <div>
      <div class="hero">
        <img src="../../assets/images/commercialMobile.png" style="width: 100%;" fxHide.gt-sm=""/>
        <img src="../../assets/images/commercialWide.png" style="width: 100%;" fxHide.lt-md=""/>
        <div class="carousel-text">
          <h1 class="header">Commercial</h1>
          <div class="description">We provide commercial construction services for large scale development projects with
            utmost dedication to quality and safety
          </div>
          <button mat-raised-button color="primary" class="details-button" routerLink="/projects/commercial">Learn more</button>
        </div>
      </div>
    </div>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <div class="dot"></div>
    </ng-template>
    <div>
      <div class="hero">
        <img src="../../assets/images/residentialMobile.png" style="width: 100%;" fxHide.gt-sm=""/>
        <img src="../../assets/images/residentialWide.png" style="width: 100%;" fxHide.lt-md=""/>
        <div class="carousel-text">
          <h1 class="header">Residential</h1>
          <div class="description">Constructing single family and multi-family homes within the twin-cities market
          </div>
          <button mat-raised-button color="primary" class="details-button" routerLink="/projects/residential">Learn more</button>
        </div>
      </div>
    </div>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <div class="dot"></div>
    </ng-template>
    <div>
      <div class="hero">
        <img src="../../assets/images/designMobile.png" style="width: 100%;" fxHide.gt-sm=""/>
        <img src="../../assets/images/designWide.png" style="width: 100%;" fxHide.lt-md=""/>
        <div class="carousel-text">
          <h1 class="header">Design/Build</h1>
          <div class="description">We create custom designs for residential properties
          </div>
          <button mat-raised-button color="primary" class="details-button" routerLink="/projects/design">Learn more</button>
        </div>
      </div>
    </div>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <div class="dot"></div>
    </ng-template>
    <div>
      <div class="hero">
        <img src="../../assets/images/residentialMobile.png" style="width: 100%;" fxHide.gt-sm=""/>
        <img src="../../assets/images/residentialWide.png" style="width: 100%;" fxHide.lt-md=""/>
        <div class="carousel-text">
          <h1 class="header">Restoration</h1>
          <div class="description">We are the Minnesota roofing and siding experts. Let us show you how we are committed to raising the standard for roofing and siding replacement.
          </div>
          <button mat-raised-button color="primary" class="details-button" routerLink="/projects/residential">Learn more</button>
        </div>
      </div>
    </div>
  </mat-tab>
</mat-tab-group>
<div class="about our-mission" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between start">
  <h1 fxFlex="25">Our Mission</h1>
  <div fxFlex="67">
    <div class="description-text">
      Queblo designs and builds high quality commercial and residential projects in the Twin-Cities market. Queblo
      consists of a Latino workforce and focuses on employing immigrant communities in MN. We are a trusted source of
      quality for both tradesmen and developers.
    </div>
    <button mat-raised-button color="accent" class="about-button" routerLink="/about/">About</button>
  </div>
</div>
<div class="project-type" fxLayout="row" fxLayoutAlign="start stretch" fxHide.lt-md="true">
  <div fxFlex="50" class="project-type-description" fxLayout="column" fxLayoutAlign="space-between">
    <h2>Commercial Projects</h2>
    <div class="description-text">
      We provide commercial construction services for large scale development projects with a dedication to quality and
      safety.
    </div>
    <a routerLink="/projects/commercial">VIEW PORTFOLIO ></a>
  </div>
  <img src="../../assets/images/projectsCommercial.png" fxFlex="50" width="100%"/>
</div>
<div class="project-type thin" fxLayout="column" fxLayoutAlign="start start" fxHide.gt-sm="true">
  <img src="../../assets/images/projectsCommercial.png" fxFlex="50" width="100%"/>
  <div fxFlex="50" class="project-type-description" fxLayout="column" fxLayoutAlign="space-between">
    <h2>Commercial Projects</h2>
    <div class="description-text">
      We provide commercial construction services for large scale development projects with a dedication to quality and
      safety.
    </div>
    <a routerLink="/projects/commercial">VIEW PORTFOLIO ></a>
  </div>
</div>
<div class="project-type" fxLayout="row" fxLayoutAlign="start stretch" fxHide.lt-md="true">
  <img src="../../assets/images/projectsResidential.png" fxFlex="50" width="100%"/>
  <div fxFlex="50" class="project-type-description" fxLayout="column" fxLayoutAlign="space-between">
    <h2>Residential Projects</h2>
    <div class="description-text">
      Constructing single family and multi-family homes within the twin-cities market.
    </div>
    <a routerLink="/projects/residential">VIEW PORTFOLIO ></a>
  </div>
</div>
<div class="project-type thin" fxLayout="column" fxLayoutAlign="start start" fxHide.gt-sm="true">
  <img src="../../assets/images/projectsResidential.png" fxFlex="50" width="100%"/>
  <div fxFlex="50" class="project-type-description" fxLayout="column" fxLayoutAlign="space-between">
    <h2>Residential Projects</h2>
    <div class="description-text">
      Constructing single family and multi-family homes within the twin-cities market.
    </div>
    <a routerLink="/projects/residential">VIEW PORTFOLIO ></a>
  </div>
</div>
<div class="project-type" fxLayout="row" fxLayoutAlign="start stretch" fxHide.lt-md="true">
  <img src="../../assets/images/projectsRestoration.jpg" fxFlex="50" width="100%"/>
  <div fxFlex="50" class="project-type-description" fxLayout="column" fxLayoutAlign="space-between">
    <h2>Restoration Projects</h2>
    <div class="description-text">
      We are the Minnesota Restoration, Roofing, and Siding Experts, helping Minnesota home owners turn their dreams into restoration reality.
    </div>
    <a routerLink="/projects/restoration">VIEW MORE DETAILS ></a>
  </div>
</div>
<div class="project-type thin" fxLayout="column" fxLayoutAlign="start start" fxHide.gt-sm="true">
  <img src="../../assets/images/projectsRestoration.jpg" fxFlex="50" width="100%"/>
  <div fxFlex="50" class="project-type-description" fxLayout="column" fxLayoutAlign="space-between">
    <h2>Restoration Projects</h2>
    <div class="description-text">
      We are the Minnesota Restoration, Roofing, and Siding Experts, helping Minnesota home owners turn their dreams into restoration reality.
    </div>
    <a routerLink="/projects/restoration">VIEW MORE DETAILS ></a>
  </div>
</div>
<div class="project-type" fxLayout="row" fxLayoutAlign="start stretch" fxHide.lt-md="true">
  <div fxFlex="50" class="project-type-description" fxLayout="column" fxLayoutAlign="space-between">
    <h2>Design | Build Projects</h2>
    <div class="description-text">
      We create custom designs for residential properties as well as re-develop existing homes, developing and
      constructing the entire project.
    </div>
    <a routerLink="/projects/design">VIEW PORTFOLIO ></a>
  </div>
  <img src="../../assets/images/projectsDesign.png" fxFlex="50" width="100%"/>
</div>
<div class="project-type thin" fxLayout="column" fxLayoutAlign="start start" fxHide.gt-sm="true">
  <img src="../../assets/images/projectsDesign.png" fxFlex="50" width="100%"/>
  <div fxFlex="50" class="project-type-description" fxLayout="column" fxLayoutAlign="space-between">
    <h2>Design | Build Projects</h2>
    <div class="description-text">
      We create custom designs for residential properties as well as re-develop existing homes, developing and
      constructing the entire project.
    </div>
    <a routerLink="/projects/design">VIEW PORTFOLIO ></a>
  </div>
</div>
<div class="about" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between start">
  <div fxFlex="70">
    <div class="description-text">
      Queblo is a Licensed General Contractor that designs and builds its own homes, while also serving developers with
      design/build service
    </div>
  </div>
  <div fxFlex="25" fxLayoutAlign="end" fxLayout="row">
    <button mat-raised-button color="accent" class="about-button contact-us-button" routerLink="/about/contact-us">Contact Us</button>
  </div>
</div>
<cloudflare-stream
  [controls]="true"
  [autoplay]="true"
  [loop]="true"
  [muted]="true"
  src="c555bea5a69a9f3af3e32b6b95ecd416"
></cloudflare-stream>
<div fxLayout="row" fxLayoutAlign="center center" class="social-media-heading">
  <h2>Check us on social media</h2>
</div>
<div fxLayout="row wrap" fxLayoutAlign="center center" class="gallery">
  <a *ngFor="let link of instFeed" [href]="link.link">
    <img src="../../assets/images/instagram/{{link.image}}"/>
  </a>
</div>
