<div class="container">
  <h1>Our Craftsmen</h1>
  <div fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="20px">
    <queblo-craftsmen-card fxFlex="" *ngFor="let person of people" [person]="person"></queblo-craftsmen-card>
  </div>
</div>
<!--<img class="full-width how-it-works" src="../../../assets/images/team/howItWorks.png"/>-->
<!--<div class="container">-->
<!--  <h1>Lorem Ipsum</h1>-->
<!--  <div class="description-text">-->
<!--    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna-->
<!--    aliqua. A pellentesque sit amet porttitor. Vitae suscipit tellus mauris a diam maecenas. Accumsan sit amet-->
<!--    nulla facilisi morbi. Odio eu feugiat pretium nibh ipsum consequat nisl. At imperdiet dui accumsan.-->
<!--  </div>-->
<!--</div>-->
<div class="container contact-info" fxLayout="row" fxLayoutAlign="space-between start" fxLayout.lt-md="column" fxLayoutAlign.lt-md="start stretch">
  <div fxFlex="20">
    <h1>Join Us</h1>
<!--    <div class="description-text">-->
<!--      Lorem ipsum dolor-->
<!--      sit amet, consectetur adipiscing elit, sed do eiusmod tempor.-->
<!--    </div>-->
  </div>
  <mat-card fxFlex="75">
    <h1>Contact Information</h1>
<!--    <div class="description-text card-text">-->
<!--      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod  tempor incididunt ut labore et dolore magna aliqua tempor.-->
<!--    </div>-->
    <div fxLayout="row wrap" fxLayoutAlign="space-between start" *ngIf="!isContactSubmitted">
      <mat-form-field fxFlex="47">
        <mat-label>First Name</mat-label>
        <input type="text" matInput [(ngModel)]="firstName"/>
      </mat-form-field>
      <mat-form-field fxFlex="47">
        <mat-label>Last Name</mat-label>
        <input type="text" matInput [(ngModel)]="lastName"/>
      </mat-form-field>
      <mat-form-field fxFlex="47">
        <mat-label>Phone Number</mat-label>
        <input type="phone" matInput [(ngModel)]="phone"/>
      </mat-form-field>
      <mat-form-field fxFlex="47">
        <mat-label>Email</mat-label>
        <input type="email" matInput [(ngModel)]="email"/>
      </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="end" *ngIf="!isContactSubmitted" class="button-container">
      <button mat-raised-button color="accent" class="submit-button" (click)="submitContactForm()">Submit</button>
    </div>
    <queblo-thank-you-card *ngIf="isContactSubmitted" (onClose)="isContactSubmitted = false;"></queblo-thank-you-card>
  </mat-card>
</div>
