import {Component, Input, OnInit} from '@angular/core';
import {Person} from './person';

@Component({
  selector: 'queblo-person-card',
  templateUrl: './person-card.component.html',
  styleUrls: ['./person-card.component.scss']
})
export class PersonCardComponent implements OnInit {
  @Input() person: Person;

  constructor() { }

  ngOnInit(): void {
  }

}
