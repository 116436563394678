import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'queblo-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.scss']
})
export class MobileMenuComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<MobileMenuComponent>) { }

  ngOnInit(): void {
  }

  closeWindow(): void {
    this.dialogRef.close();
  }
}
