import {BrowserModule, HammerModule} from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { YouTubePlayerModule } from '@angular/youtube-player';
import { CloudflareStreamModule } from '@cloudflare/stream-angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRippleModule } from '@angular/material/core';
import { MatTabsModule } from '@angular/material/tabs';
import { LandingComponent } from './landing/landing.component';
import { AboutComponent } from './about/about.component';
import { ProjectComponent } from './project/project.component';
import { ProjectsListComponent } from './projects-list/projects-list.component';
import { InfoComponent } from './about/info/info.component';
import { OurTeamComponent } from './about/our-team/our-team.component';
import { OurCraftsmenComponent } from './about/our-craftsmen/our-craftsmen.component';
import { ContactUsComponent } from './about/contact-us/contact-us.component';
import { NewsComponent } from './about/news/news.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { PersonCardComponent } from './person-card/person-card.component';
import { CraftsmenCardComponent } from './craftsmen-card/craftsmen-card.component';
import { MatCardModule } from '@angular/material/card';
import { ArticleCardComponent } from './article-card/article-card.component';
import { ThankYouCardComponent } from './thank-you-card/thank-you-card.component';
import { YoutubePlayerComponent } from './youtube-player/youtube-player.component';
import { TermsOfUseComponent } from './terms-of-use/terms-of-use.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import {FormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {ContactService} from './service/contact.service';
import {NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule} from 'ngx-google-analytics';
import { MobileMenuComponent } from './mobile-menu/mobile-menu.component';
import {MatDialogModule} from '@angular/material/dialog';
import {InstService} from './inst.service';

import {VgCoreModule} from '@videogular/ngx-videogular/core';
import {VgControlsModule} from '@videogular/ngx-videogular/controls';
import {VgBufferingModule} from '@videogular/ngx-videogular/buffering';
import {VgStreamingModule} from '@videogular/ngx-videogular/streaming';
import {VgOverlayPlayModule} from '@videogular/ngx-videogular/overlay-play';

@NgModule({
  declarations: [
    AppComponent,
    LandingComponent,
    AboutComponent,
    ProjectComponent,
    ProjectsListComponent,
    InfoComponent,
    OurTeamComponent,
    OurCraftsmenComponent,
    ContactUsComponent,
    NewsComponent,
    PersonCardComponent,
    CraftsmenCardComponent,
    ArticleCardComponent,
    ThankYouCardComponent,
    YoutubePlayerComponent,
    TermsOfUseComponent,
    PrivacyPolicyComponent,
    MobileMenuComponent
  ],
  imports: [
    BrowserModule,
    HammerModule,
    NgxGoogleAnalyticsModule.forRoot('UA-63297144-1'),
    NgxGoogleAnalyticsRouterModule,
    HttpClientModule,
    AppRoutingModule,
    FlexLayoutModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    MatRippleModule,
    MatTabsModule,
    MatMenuModule,
    MatDialogModule,
    BrowserAnimationsModule,
    MatIconModule,
    MatCardModule,
    YouTubePlayerModule,
    VgControlsModule,
    VgCoreModule,
    VgBufferingModule,
    VgStreamingModule,
    VgOverlayPlayModule,
    CloudflareStreamModule,
    FormsModule,
  ],
  providers: [
    ContactService,
    InstService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
