import { Component, OnInit } from '@angular/core';
import people from '../../data/people.json';

@Component({
  selector: 'queblo-our-team',
  templateUrl: './our-team.component.html',
  styleUrls: ['./our-team.component.scss']
})
export class OurTeamComponent implements OnInit {
  people = people.team;

  constructor() { }

  ngOnInit(): void {
  }

}
